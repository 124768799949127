import React, { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';

import { getConfig } from "../config";
import logo from "../assets/z-insurance-logo-nobg.png";

const Hero = (props) => {
    const { apiOrigin, audience } = getConfig();

    const [deviceCode, setDeviceCode] = useState(null);
    const [delivered, setDelivered] = useState(false);
    const [idToken, setIdToken] = useState(null);
    const [state, setState] = useState({
      showQRCode: false,
      deviceRegistered: false,
      responseData: {},
      error: null,
    });

    useEffect(() => {
      if (deviceCode) {
        pollAuthorizationEndpoint();
      }
    }, [deviceCode]);

    const callApi = async () => {
      try {
  
        const response = await fetch(`${apiOrigin}/api/device-code`);
        const responseData = await response.json();

        setState({
          ...state,
          showQRCode: true,
          responseData,
        });
        setDeviceCode(responseData.device_code);
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    };

    const claimPackage = async () => {
      props.handleClaim(packageDelivered);
      setDelivered(true);
    }

    const packageDelivered = () => {
      setDelivered(false);
      setIdToken(null);
      setState({
        showQRCode: false,
        deviceRegistered: false,
        responseData: {},
        error: null,
      });
      props.handleClaim();
    };

    const pollAuthorizationEndpoint = async () => {
      try {
        const response = await fetch('https://nixu-dev.eu.auth0.com/oauth/token', {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
            device_code: deviceCode,
            client_id: 'iUoswwdRyQzTl1HQudyupf7o8AES9y3T'
          })
        });
        
        const data = await response.json();

        if (data.error === 'authorization_pending') {
          console.error('Error polling authorization endpoint:', data.error);
          // Continue polling after a delay
          setTimeout(pollAuthorizationEndpoint, state.responseData.interval * 1000);
        } else if(data.error === 'slow_down') {
          setTimeout(pollAuthorizationEndpoint, state.responseData.interval * 2000);
        } else {
          console.log('Authorization successful');
          setIdToken(jwtDecode(data.id_token));
          setState({ ...state, showQRCode: false });
        }
      } catch (error) {
        console.error('Error polling authorization endpoint:', error);
        // Continue polling after a delay
        setTimeout(pollAuthorizationEndpoint, state.responseData.interval * 1000);
      }
    }

    return (
      <div className="text-center hero mt-5">
        { !idToken && 
          <div>
            <img className="mb-3 app-logo" src={logo} alt="React logo" width="80" />
            <h1 className="mb-3 app-headline">Z Delivery</h1>
          </div>
        }

        { idToken &&
          <div>
            <img className="mb-3 app-logo profile" src={idToken.picture} alt={idToken.name} width="80" />
            <h1 className="mb-3 app-headline">{idToken.name}</h1>
            <code className="white">
              You have 1 package to claim!
            </code>
            <button className="btn btn-primary mt-5" onClick={claimPackage}>
              Claim the Package
            </button> 
          </div>
        }

        { !state.showQRCode && !idToken && 
          <button className="btn btn-primary mt-5" onClick={callApi}>
            Claim a Package
          </button> 
        }

        { state.showQRCode &&
          <div>
            <code className="code">{state.responseData.user_code}</code><br />
            <img className="qr-code" alt={state.responseData.code} src={state.responseData.url} /> 
          </div>
        }
      </div>
      
    );
};

export default Hero;
