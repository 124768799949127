import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = () => (
  <div className="spinner">
    <FontAwesomeIcon icon="spinner fa-spin" />
  </div>
);

export default Loading;
