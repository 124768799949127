import React, { useRef, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import Home from "./views/Home";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  const elementRef = useRef();


  useEffect(() => {
    // This ensures the ref is properly assigned after the component mounts
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleClaim = (callback) => {
    if(elementRef.current) {
      elementRef.current.classList.toggle("delivered");
    }
    if(callback) {
      setTimeout(callback, 3000);
    }
  };

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Container className="z-delivery-screen">
          <Switch>
            <Route path="/" exact>
              <Home handleClaim={handleClaim} />
            </Route>
          </Switch>
        </Container>
        <div ref={elementRef} className="z-package-overlay" />
      </div>
    </Router>
  );
};

export default App;
