import React, { Fragment } from "react";

import Hero from "../components/Hero";

const Home = (props) => (
  <Fragment>
    <Hero handleClaim={ props.handleClaim }/>
  </Fragment>
);

export default Home;
